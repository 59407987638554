/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    br: "br"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, MapInfo, SideBySide, LandingPageCta, ThreeColCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/technology/rechargeable/'], ['en', 'https://www.hear.com/hearing-aids/technology/rechargeable/'], ['en-US', 'https://www.hear.com/hearing-aids/technology/rechargeable/'], ['en-CA', 'https://ca.hear.com/hearing-aids/technology/rechargeable/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "the-best-rechargeable-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-best-rechargeable-hearing-aids",
    "aria-label": "the best rechargeable hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Best Rechargeable Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Through extensive research, we have found that rechargeable ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " are suitable for all levels of hearing loss. So, no matter how severe or moderate your loss is, you can qualify for them. Currently, they are only available as BTE devices. However, they are quite discreet and small with the same advanced technology as the other hearing aids. So, you will still be getting the same high quality hearing experience."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Batteries are an important part of any hearing aid and it can be a frequent hassle for some users. This ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/",
    className: "c-md-a"
  }, "new hearing aids technology"), " works to alleviate any frustration regarding a battery because you can recharge your hearing aids on the go. Now you can enjoy all the sounds without the worry."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-do-rechargeable-hearing-aids-work",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-do-rechargeable-hearing-aids-work",
    "aria-label": "how do rechargeable hearing aids work permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How do rechargeable hearing aids work?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Rechargeable hearing aids work similarly to rechargeable batteries. However, the difference is that rather than charging the batteries at night you would charge your actual devices. The hearing aids come with an easy to use charging pod that you can place your devices in at night, and then use them again in the day as normal. You can also carry the pod with you to charge on the go. Never miss out on a sound! It’s truly a simple solution for many people who fumble with batteries, forget to bring extra, lose them, etc."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Imagine you are in a hurry to get to work, you are already 20 minutes late. Just then, your hearing aids start indicating that you need to replace the batteries. When you go to check for the batteries, you don’t have any left. This is not only a waste of time but an extra hassle added to your day-to-day life. Rechargeable devices ensure that you don’t have to worry about that. You can recharge your devices every night and use them in the day. This saves time and you don’t have to worry about constantly spending money on new batteries. You can recharge your hearing aids for free! Another benefit of the rechargeable digital aid is that, people with limited dexterity don’t have to worry about changing small batteries which can be a problem for them"), "\n", React.createElement(MapInfo), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "manufacturers-of-rechargeable-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#manufacturers-of-rechargeable-hearing-aids",
    "aria-label": "manufacturers of rechargeable hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Manufacturers of rechargeable hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We have come a long way from large behind-the-ear hearing aids to small in-the-ear digital hearing aids. However, this latest revolution isn’t produced by all manufacturers but there are still many options on the market. Phonak, Signia and ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), " are two of the biggest manufacturers to produce these rechargeable devices."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "horizon-go-ix-hearing-aids-by-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#horizon-go-ix-hearing-aids-by-hearcom",
    "aria-label": "horizon go ix hearing aids by hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Horizon Go IX Hearing Aids by hear.com"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ix-hearing-aids-box-black.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Engineered for active people who desire world-class hearing that lasts all day, the ", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/go-ix/",
    className: "c-md-a"
  }, "Horizon Go IX by hear.com"), " rises to the top of its class with an extended battery life that only stops when you do. With seamless connectivity and no tiny batteries to change, staying connected while on the go has never been so easy.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Employing two distinct processors enables the specialized handling of speech and noise, resulting in an enhanced overall auditory encounter. Through the prioritization of speech and the attenuation of noise, these setups facilitate superior comprehension of verbal communication, particularly in demanding scenarios like bustling public spaces, loud workplaces, and outdoor locales.")), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "phonaks-rechargeable-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#phonaks-rechargeable-hearing-aid",
    "aria-label": "phonaks rechargeable hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Phonak's Rechargeable Hearing Aid"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/phonak-marvel-on-hand.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Phonak released ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/phonak/marvel/",
    className: "c-md-a"
  }, "a new platform called Marvel"), ". While it has many other added functionalities to make it highly beneficial, one of the main features was the recharging option. The behind the ear hearing aids come with a charging pod that people can carry around with them and charge as and when necessary. A quick 30-minute charge will ensure the hearing instruments work for 6 hours. For a full day of use, you just need to charge them for 3 hours. The long battery life means you can charge them while you sleep at night without worrying about your hearing loss affecting your life."), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Customers have shown high level of satisfaction with this product, encouraging Phonak to further manufacture ", React.createElement(_components.a, {
    href: "/hearing-aids/types/ite/",
    className: "c-md-a"
  }, "in-the-ear devices"), " with similar functions.")), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "signia",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signia",
    "aria-label": "signia permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signia"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-pure-charger.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Signia is one of the leading manufacturers in the industry and they have proved themselves yet again by creating their ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/signia/pure-charge-go-ix/",
    className: "c-md-a"
  }, "Signia Pure Charge & Go IX"), ". Siginia Pure Charge & Go IX is a RIC (receiver in the canal) device with amazing up to date and high power technology. Now, it has the added feature of recharging. The device comes with a charger that you can put the devices in; the smart technology means that the hearing aids turn themselves off when put in the charger and turn themselves on automatically, when removed from the charging case. This means the hearing aid wearer doesn’t have to worry about anything other than putting them in their ears and taking them out to charge again. It’s quite simple.")), "\n", React.createElement(LandingPageCta, {
    copy: "Test rechargeable hearing aids now",
    classes: "c-cta--content"
  }), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/oticon-opn-hearing-aid.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "oticon-opn",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#oticon-opn",
    "aria-label": "oticon opn permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Oticon Opn"), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/oticon/",
    className: "c-md-a"
  }, "Oticon Opn"), " offers the simplicity of rechargeable batteries, the groundbreaking open sound experience, and outstanding connectivity to other devices. This discreet hearing aid comes with a sleek and stylish charger and long-lasting rechargeable batteries.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "can-i-afford-rechargeable-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#can-i-afford-rechargeable-hearing-aids",
    "aria-label": "can i afford rechargeable hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Can I afford rechargeable hearing aids?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Upgrading to the latest technology generally comes at a higher price. Hearing aids are medical devices and to get the latest technology and good quality hearing experience you have to pay a little more than you pay for the cheap aids you might order online. The rechargeable digital aid is likely to be expensive but you will be saving money in the long run as you won’t have to spend money on getting new batteries every month. It is important to understand that a cheap hearing aid is likely to be second-hand and may not function correctly. When it comes to buying hearing aids, you should ", React.createElement(_components.a, {
    href: "/audiologists/",
    className: "c-md-a"
  }, "consult a specialist audiologist"), " who can answer any questions you may have and help you choose a good quality device that will suit your needs and improve your life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The cost of hearing aids can be an important factor for many users. If you are concerned about prices and feel that you will be unable to afford such helpful rechargeable hearing aids, then you can speak with our highly-trained consultants who can review out payment plans with you. These plans can be flexible to make it easier to purchase the hearing aids you need."), "\n", React.createElement(LandingPageCta, {
    copy: "Test rechargeable hearing aids now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
